const settings = {};

const api = {
  adminLogin: '/api/login',
  resetPassword: '/api/reset-password',
  newPassword: '/api/new-password',
  verifyOtp: '/api/direct/su/verify-otp',
  bulkUpload: process.env.REACT_APP_DIRECT_URL + '/su/bulk-upload',
  templateDownload: process.env.REACT_APP_DIRECT_URL + '/su/template-download',
  schoolEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-school',
  suAdminEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-admin',
  susEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-sus',
  studentEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-student',
  schoolRegister: process.env.REACT_APP_DIRECT_URL + '/su/register-school',
  susRegister: process.env.REACT_APP_DIRECT_URL + '/su/register-sus',
  parentRegister: process.env.REACT_APP_DIRECT_URL + '/su/register-parent',
  schoolDelete: process.env.REACT_APP_DIRECT_URL + '/su/delete-school',
  susDelete: process.env.REACT_APP_DIRECT_URL + '/su/delete-sus',
  parentDelete: process.env.REACT_APP_DIRECT_URL + '/su/delete-parent',
  schoolStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/status-change',
  coachStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/coach-status-change',
  parentStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/parent-status-change',
  suAdminStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/suadmin-status-change',
  testStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/test-status-change',
  suAdminDelete: process.env.REACT_APP_DIRECT_URL + '/su/suadmin-delete',
  suAdminAdd: process.env.REACT_APP_DIRECT_URL + '/su/suadmin-add',
  testAdd: process.env.REACT_APP_DIRECT_URL + '/su/test-add',
  testEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-test',
  testDelete: process.env.REACT_APP_DIRECT_URL + '/su/test-delete',
  countriesUpdate: process.env.REACT_APP_DIRECT_URL + '/su/countries-update',
  getWelcome: process.env.REACT_APP_API_URL + '/CommWelcome.json',
  getProfile: process.env.REACT_APP_API_URL + '/CommProfile.json',
  getProfileFields: process.env.REACT_APP_API_URL + '/CommProfileFields.json',
  getCountries: process.env.REACT_APP_API_URL + '/CommCountries.json',
  getLinkPayment: process.env.REACT_APP_DIRECT_URL + '/ipg-bridge/create-payment-request',
  getGateways: process.env.REACT_APP_API_URL + '/CommGateways.json',
  getGatewayCurrencies: process.env.REACT_APP_API_URL + '/CommGatewayCurrencies.json',
  getLinks: process.env.REACT_APP_API_URL + '/CommPayments.json',
  getSettings: process.env.REACT_APP_API_URL + '/CommSettings.json'
};

export { api, settings };
