import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import Flags from 'country-flag-icons/react/3x2';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { languageState } from '../atoms';

const Lang = () => {
  const [lang, setlang] = useRecoilState(languageState);

  const locales = {
    en: { code: 'en', title: 'English', country: 'United States', flagComp: Flags.US },
    nl: { code: 'nl', title: 'Dutch', country: 'Netherlands', flagComp: Flags.NL },
  };

  const changeLanguage = (locale) => {
    i18n.changeLanguage(locale);
    setlang(locale);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
    console.log(i18n.resolvedLanguage === lang);
    setlang(lang);
  }, []);

  const { t, i18n } = useTranslation();
  const [defaultLng, setDefaultLang] = useState('En');

  return (
    <div className="w-100 absolute right-0 md:top-0 sm:top-5 lg:top-0 hidden">
      <div id="" className="z-50 relative top-8 float-right right-6 mb-2 w-56 text-right">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-none px-4 py-0 text-sm font-medium uppercase text-gray-900 hover:text-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              {lang}
              <ChevronDownIcon className="ml-2 -mr-1 h-5 w-5 text-black hover:text-black" aria-hidden="true" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-1 px-2 py-1 w-22 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 ">
              {Object.keys(locales).map((locale) => (
                <div className="px-1 py-0.5 ">
                  <Menu.Item key={locale}>
                    <button
                      style={{ fontWeight: locale === lang ? 'bold' : 'normal' }}
                      type="submit"
                      onClick={() => {
                        changeLanguage(locale);
                      }}
                      className="flex w-16 flex-row justify-between"
                    >
                      {React.createElement(locales[locale].flagComp, {
                        title: 'United States',
                        className: 'w-6 mt-1',
                      })}

                      <span className="text-sm ml-1 w-10">{locales[locale].title}</span>
                    </button>
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default Lang;
