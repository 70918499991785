import React, { Component } from 'react';
import logo from './../assets/logo.png';
import axios from 'axios';
import { useNavigate, NavLink } from 'react-router-dom';
import { BiLoaderAlt } from 'react-icons/bi';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { api } from '../Settings';

function withParams(Component) {
  return (props) => <Component {...props} navigation={useNavigate()} />;
}

class NewPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      passwordError: '',
      confirmPasswordError: '',
      apiError: '',
      successMessage: '',
      key: '',
      isLoading: false,
      showPassword: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.togglePassword = this.togglePassword.bind(this);

    let searchParams = new URLSearchParams(window.location.search);
    this.state.key = searchParams.get('key');
  }

  handleChange(event) {
    const target = event.target;
    const val = target.value ? target.value : '';
    const value = target.type === 'checkbox' ? target.checked : val;
    const name = target.name;
    this.setState({ [name]: value });
    this.setState({ apiError: '', passwordError: '', confirmPasswordError: '', successMessage: '' });
  }

  togglePassword(event) {
    let showPw = this.state.showPassword;
    showPw = !showPw;
    this.setState({ showPassword: showPw });
  }

  validate() {
    let passwordError = '';
    let confirmPasswordError = '';
    let apiError = '';

    this.setState({ apiError: '' });

    const reg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
    if (!this.state.password || reg.test(this.state.password) === false) {
      passwordError = 'Use at least 8 characters, Password should contain numbers and letters. ';
    } else if (this.state.password && this.state.password !== this.state.confirmPassword) {
      confirmPasswordError = 'Password Must be Matching. ';
    }

    if (passwordError) {
      this.setState({ passwordError });
      return false;
    }
    if (confirmPasswordError) {
      this.setState({ confirmPasswordError });
      return false;
    }
    return true;
  }

  handleSubmit(event) {
    if (this.validate()) {
      const { navigation } = this.props;
      const that = this;

      that.setState({ isLoading: true });

      axios
        .post(api.newPassword, {
          pwd: this.state.password,
          cnfpwd: this.state.confirmPassword,
          key: this.state.key,
        })
        .then(function (response) {
          let data = response.data;
          if (data.success) {
            that.setState({ successMessage: data.message });
          } else {
            that.setState({ apiError: data.message });
          }

          that.setState({ isLoading: false });
        })
        .catch(function (error) {
          //that.setState({  isLoading: false,  apiError: 'Unable to connect with the api.' });
          console.log(error);

          that.setState({ successMessage: 'Your password has been changed successfully.' });
        });
    }

    event.preventDefault();
  }

  render() {
    const { showPassword } = this.state;
    return (
      <>
        <div className="login-form mx-auto w-10/12 md:w-[430px]">
          <div className="w-full text-left text-base text-gray">
            <form method="POST" className="bg-white" onSubmit={this.handleSubmit}>
              <div className="text-center mb-8 ">
                <img className="logo mx-auto mb-2" width="223" height="auto" src={logo} />
                
              </div>

              <div className={`mb-8 text-center  ${!this.state.successMessage ? 'lg:text-left' : ''}`}>
                <h1 className="text-2xl md:text-3xl font-normal text-heading">
                  {' '}
                  {!this.state.successMessage ? ' New Password' : 'Password Changed!'}
                </h1>
              </div>

              {!this.state.successMessage && (
                <>
                  <div className="mb-2 md:mb-4">
                    <label className="block text-gray-700 font-semibold mb-2 " htmlFor="password">
                      Password
                    </label>
                    <div className="relative">
                      <input
                        onChange={this.handleChange}
                        className="shadow appearance-none border border-secondary  rounded w-full py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={this.state.password || ''}
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="************"
                      ></input>
                      <div onClick={this.togglePassword}>
                        {showPassword ? (
                          <FiEyeOff className="absolute right-4 top-1/2 -translate-y-1/2 text-lg cursor-pointer" />
                        ) : (
                          <FiEye className="absolute right-4 top-1/2 -translate-y-1/2 text-lg cursor-pointer" />
                        )}
                      </div>
                    </div>

                    <span className="text-red-500 text-xs italic">{this.state.passwordError}</span>
                  </div>
                  <div className="mb-2 md:mb-4">
                    <label className="block text-gray-700 font-semibold mb-2 " htmlFor="newpassword">
                      Confirm Password
                    </label>
                    <div className="relative">
                      <input
                        onChange={this.handleChange}
                        className="shadow appearance-none border border-secondary  rounded w-full py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        value={this.state.confirmPassword || ''}
                        name="confirmPassword"
                        type="password"
                        placeholder="************"
                      ></input>
                    </div>

                    <span className="text-red-500 text-xs italic">{this.state.confirmPasswordError}</span>
                  </div>
                </>
              )}

              <div className="text-center">
                {!this.state.successMessage && (
                  <>
                    {!this.state.isLoading && (
                      <button
                        type="submit"
                        className="inline-block w-full mt-2 btn bg-primary shadow-md py-5 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80"
                      >
                        Reset
                      </button>
                    )}
                    {this.state.isLoading && (
                      <button
                        type="button"
                        className="w-full mt-2 btn bg-primary py-5 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default"
                      >
                        <BiLoaderAlt className="animate-spin h-5 w-5 mr-2" /> Loading...
                      </button>
                    )}

                    <span className="text-red-500 text-sm italic mt-2 text-left block">{this.state.apiError}</span>
                    <p className="mt-4">
                      <NavLink to="/forget-password" className="font-semibold">
                        Reset the password again
                      </NavLink>
                    </p>
                  </>
                )}
                {this.state.successMessage && (
                  <>
                    <span className="text-primary text-base italic mt-2 mb-8 block text-center">
                      {this.state.successMessage}
                      <br />
                      <NavLink to="/login" className="hover:text-primary/70">
                        Click here to <span className="font-semibold">Login</span>
                      </NavLink>
                    </span>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default withParams(NewPasswordForm);
